.empty__fields {
  text-align: center;
  margin-top: 15%;
}

.accordionItem {
  margin: 20px;
  color: #282c34;
}

.group__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: bolder;
}

.group__information {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.groupName__icon {
  display: flex;
}

.accDetails {
  margin-top: 25px;
  transition-delay: 500ms;
}

.lessonDate {
  font-size: 14px;
  color: rgb(129, 129, 129);
}

.selectBtn {
  font-size: 14px;
}

.textLable {
  font-size: 18px;
  margin-bottom: 15px;
  line-height: 1;
}

.topic__wrapper {
  display: flex;
  justify-content: space-between;
}

.textInput {
  margin-bottom: 20px;
}

.sendBtn {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.groupName {
  margin-left: 10px;
}

.iconSpan {
  width: 25px;
}

@media (max-width: 991px) {
  .tableOpenBtn {
    line-height: 1;
    padding-top: 0;
    padding-bottom: 0;
  }

  .group__information {
    line-height: 1;
  }

  .accordionItem {
    margin: 20px 5px;
  }
}

/* ---- view ----- */

.methodist__actions {
  margin: 0 30px 20px 30px;
}

.group__header {
  padding: 5px 16px;
}

.selection__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 20px 0 10px 20px;
}

.input_box {
  width: 25%;
  margin-right: 30px;
}

.submit_btn_view {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.table__wrapper {
  padding-bottom: 50px;
}
.schedule__results {
  margin-top: 30px;
}
.schedule__results tbody {
  margin-bottom: 20px;
  border-bottom: 50px;
}
.schedule__results td,
.schedule__results th,
.schedule__results tr {
  padding: 10px 5px;
  border: 1px solid #ddd;
}

.invalid-cell {
  background-color: #fa8e64; /* Красный цвет для невалидной ячейки */
  color: #fff; /* Белый текст для контраста */
  font-weight: bold;
  border: 1px solid #d32f2f; /* Красная рамка */
}
